import React, { ReactNode } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';

import Paragraph from '../components/founding-member/Paragraph';

function renderRichBodyText<
  TReference extends ContentfulRichTextGatsbyReference
>(data: RenderRichTextData<TReference>): ReactNode {
  return renderRichText(
    {
      raw: data.raw,
      references: data.references || [],
    },
    {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (_, children) => <Paragraph>{children}</Paragraph>,
      },
      renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      },
    }
  );
}

export default renderRichBodyText;
