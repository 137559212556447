import React from 'react';
import styled from '@emotion/styled';
import { COLORS } from '../../ui/variables';

const Container = styled.p`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
  margin-top: 24px;
  margin-bottom: 48px;
  a {
    color: ${COLORS.NEW_ORANGE};
    max-width: 1170px;
  }
`;

const Paragraph = ({ children }: { children: React.ReactNode }) => (
  <Container>{children}</Container>
);

export default Paragraph;
